const rules = {
  required: [(v) => !!v || "Campo requerido"],

  validDate: [
    (v) =>
      /^((((0?[1-9]|1\d|2[0-8])\/(0?[1-9]|1[0-2]))|((29|30)\/(0?[13-9]|1[0-2]))|(31\/(0?[13578]|1[02])))\/(19|[2-9]\d)\d{2})$|^(29\/0?2\/(19|[2-9]\d)(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96))$/.test(
        v
      ) || "Formato de fecha no válido",
  ],

  periodoMmYy: [
    // para vencimiento tarjeta
    (value) =>
      !value ||
      (value.split("/")[0] < 13 && value.length === 5) ||
      "Ingrese un formato válido",
  ],
  validateEmailFormat: (value) => {
    return (value) =>
      !value ||
      (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      ) &&
        value.length <= 60) ||
      "Formato de email inválido";
  },
};

export default rules;
