<template>
  <v-app>
    <v-main>
      <!-- Mostrar el spinner de carga mientras se realiza la solicitud -->
      <v-overlay :value="loading">
        <div class="text-center">
          <!-- Contenedor principal centrado -->
          <v-card class="transparent" elevation="0">
            <v-card-title class="primary--text pb-10 pt-0"
              >Generando token de pago</v-card-title
            >
            <v-progress-circular
              indeterminate
              size="125"
              color="primary"
            ></v-progress-circular>
          </v-card>
        </div>
      </v-overlay>

      <v-container v-if="!loading">
        <v-card elevation="10">
          <v-row>
            <!-- Datos de la transacción -->
            <v-col cols="12">
              <v-card elevation="5" class="primary">
                <v-row>
                  <v-col cols="12" class="white--text d-flex align-center pb-0">
                    <v-card-title class="white--text mx-auto py-0"
                      >Cuota del afiliado</v-card-title
                    >
                  </v-col>
                  <v-col cols="12" class="d-flex align-center pb-0">
                    <span class="white--text mx-auto">{{
                      numeroComprobante
                    }}</span>
                  </v-col>
                  <v-col cols="12" class="d-flex align-center">
                    <span class="white--text mx-auto"
                      >${{ montoComprobante }}</span
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <!-- Formulario de pago -->
            <v-col cols="12" class="py-0">
              <v-card-text>
                <v-form @submit.prevent="submitPayment" v-model="isFormValid">
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <v-select
                          :items="mediosPago"
                          v-model="medioPagoSelected"
                          item-value="medioPagoId"
                          :rules="rules.required"
                          item-text="nombre"
                          return-object
                          label="Medio de Pago"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-spacer></v-spacer>
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <v-text-field
                          v-model="datosTarjeta.NumeroTarjeta"
                          label="Número de tarjeta"
                          inputmode="numeric"
                          v-mask="'#### #### #### ####'"
                          append-icon="mdi-credit-card-outline"
                          :rules="rules.required"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" sm="6" class="py-0">
                        <v-text-field
                          v-model="vencimientoTarjeta"
                          label="Vencimiento"
                          hint="MM/AA"
                          inputmode="numeric"
                          v-mask="'##/##'"
                          :rules="rules.required.concat(rules.periodoMmYy)"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="6" sm="6" class="py-0">
                        <v-text-field
                          v-model="datosTarjeta.CodigoTarjeta"
                          label="CVV"
                          inputmode="numeric"
                          v-mask="'####'"
                          append-icon="mdi-credit-card-settings-outline"
                          :rules="rules.required"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" class="py-0">
                        <v-text-field
                          v-model="datosTarjeta.TitularTarjeta"
                          label="Nombre en la tarjeta"
                          :rules="rules.required"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" class="py-0">
                        <v-text-field
                          v-model="datosTarjeta.DocumentoTitular"
                          label="Número de documento"
                          inputmode="numeric"
                          v-mask="['#.###.###', '##.###.###']"
                          :rules="rules.required"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" class="py-0">
                        <v-text-field
                          v-model="datosTarjeta.Email"
                          label="Email"
                          hint="Email donde se enviará el comprobante de pago"
                          persistent-hint
                          inputmode="email"
                          :rules="
                            rules.required.concat(
                              rules.validateEmailFormat(datosTarjeta.Email)
                            )
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-text-field
                          v-model="datosTarjeta.FechaNacimientoTitular"
                          label="Fecha de nacimiento del titular"
                          inputmode="numeric"
                          v-mask="'##/##/####'"
                          :rules="rules.required.concat(rules.validDate)"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <!-- <v-col cols="12">
                <v-checkbox
                  v-model="aceptaTerminosCondiciones"
                  label="Acepta Términos y Condiciones"
                ></v-checkbox>
              </v-col> -->
                  </v-container>
                  <v-btn
                    :disabled="!isFormValid || loadingPayment"
                    block
                    type="submit"
                    color="success"
                    >Pagar</v-btn
                  >
                </v-form>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
        <v-overlay v-if="loadingPayment" v-model="loadingPayment">
          <div class="text-center" v-show="!alert">
            <!-- Contenedor principal centrado -->
            <v-card class="transparent" elevation="0">
              <v-card-title class="primary--text pb-10 pt-0"
                >Procesando el pago</v-card-title
              >
              <v-progress-circular
                indeterminate
                size="125"
                color="primary"
              ></v-progress-circular>
            </v-card>
          </div>
        </v-overlay>
      </v-container>
    </v-main>
    <v-dialog v-if="alert" v-model="alert" persistent>
      <v-alert v-show="alert" class="mb-0" prominent :type="messageColor">
        <v-row>
          <v-col cols="12" class="d-flex align-center">
            <span class="mx-auto">{{ responseMessage }}</span>
          </v-col>
          <v-col v-if="datosTransaccion" cols="12" class="d-flex align-center">
            <span class="mx-auto">{{ datosTransaccion?.FechaPago }}</span>
          </v-col>
        </v-row>
      </v-alert>
      <v-alert
        v-if="datosTransaccion"
        prominent
        color="success white--text mt-0 mb-0"
      >
        <v-row>
          <v-col cols="12" class="d-flex align-center pt-0">
            <span class="mx-auto"
              >Se enviará el comprobante al email ingresado</span
            >
          </v-col>
          <v-col cols="12" class="d-flex align-center">
            <v-btn
              block
              @click="goToUrl()"
              color="success"
              elevation="20"
              class="mx-auto"
              >Volver a la app
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>
  </v-app>
</template>

<script>
import rules from "@/utils/helpers/rules.js";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";

export default {
  directives: { mask },
  name: "PlusPagosForm",
  data() {
    return {
      vencimientoTarjeta: null,
      datosTransaccion: null,
      loading: true,
      loadingPayment: false,
      mediosPago: [],
      montoComprobante: null,
      numeroComprobante: null,
      xToken: null,
      bpeId: null,
      isFormValid: false,
      entFacId: null,
      rules: rules,
      alert: false,
      responseMessage: "",
      messageColor: "success",
      datosTarjeta: {
        YearVencimiento: "",
        MesVencimiento: "",
        CodigoTarjeta: "",
        DocumentoTitular: "",
        Email: "",
        FechaNacimientoTitular: "",
        NumeroPuertaResumen: "20",
        NumeroTarjeta: "",
        TipoDocumento: "DNI",
        TitularTarjeta: "",
      },
      aceptaHabeasData: false,
      aceptaTerminosCondiciones: false,
      cantidadCuotas: 1,
      medioPagoSelected: null,
    };
  },

  mounted() {

    if (this.compId) {
      this.getDatosIntencionPago();
    } else {
      console.error("No se ingresó el número de comprobante");
    }
  },

  computed: {
    compId() {
      return encodeURIComponent(this.$attrs.comp);
    },
    returnUrl() {
      return decodeURIComponent(this.$attrs.returnUrl);
    },
  },
  methods: {
    ...mapActions({
      getIntencionPagoPlusPagos: "pagos/getIntencionPagoPlusPagos",
      executePagoPlusPagos: "pagos/executePagoPlusPagos",
      getSesionToken: "pagos/getSesionToken",
    }),
    goToUrl() {
      window.location.replace(this.returnUrl);
    },

    async getDatosIntencionPago() {
      try {
        // await this.getTokenSesion();
        const response = await this.getIntencionPagoPlusPagos(this.compId);
        if (response.status == 200) {
          const responseData = response.data.data;
          this.mediosPago = responseData.mediosPago;
          this.montoComprobante = responseData.montoComprobante;
          this.numeroComprobante = responseData.numeroComprobante;
          this.xToken = responseData.xToken;
          this.entFacId = responseData.entFacId;
          this.bpeId = responseData.bpeId;
          this.loading = false;
        } else if (response.status == 400) {
          this.loading = false;
          this.responseMessage = response.data;
          this.messageColor = "error";
          this.alert = true;
        } else {
          this.responseMessage = "Ocurrió un error inesperado.";
          this.messageColor = "error";
          this.alert = true;
        }
      } catch (error) {
        this.responseMessage =
          "No se pudieron obtener los datos para realizar el pago";
        this.messageColor = "error";
        this.alert = true;
      }
    },

    async submitPayment() {
      this.loadingPayment = true;
      const [month, year] = this.vencimientoTarjeta.split("/");
      const numeroTarjeta = this.datosTarjeta.NumeroTarjeta.replaceAll(" ", "");
      const documentoTitular = this.datosTarjeta.DocumentoTitular.replaceAll(
        ".",
        ""
      );
      const parsedDate = this.datosTarjeta.FechaNacimientoTitular.replaceAll(
        "/",
        ""
      );
      const data = {
        DatosTarjeta: {
          AñoVencimiento: year.toString(),
          MesVencimiento: month.toString(),
          CodigoTarjeta: this.datosTarjeta.CodigoTarjeta,
          DocumentoTitular: documentoTitular,
          Email: this.datosTarjeta.Email,
          FechaNacimientoTitular: parsedDate,
          NumeroPuertaResumen: this.datosTarjeta.NumeroPuertaResumen,
          NumeroTarjeta: numeroTarjeta,
          TipoDocumento: this.datosTarjeta.TipoDocumento,
          TitularTarjeta: this.datosTarjeta.TitularTarjeta,
        },
        AceptaHabeasData: false,
        AceptTerminosyCondiciones: true,
        CantidadCuotas: 1,
        IPCliente: "",
        MedioPagoId: this.medioPagoSelected?.medioPagoId,
        EntFacId: this.entFacId,
        XToken: this.xToken,
        BpeId: this.bpeId,
      };

      const response = await this.executePagoPlusPagos(data);
      const responseData = response.data;

      if (responseData && responseData.code === 200) {
        this.datosTransaccion = JSON.parse(responseData.data);
        this.responseMessage = this.datosTransaccion.Detalle;
        this.messageColor = "success";
        this.alert = true;
        // setTimeout(() => {
        //   window.location.href = this.returnUrl;
        // }, 3000);
      } else {
        this.responseMessage = responseData.message;
        this.messageColor = "error";
        this.alert = true;
        setTimeout(() => {
          this.loadingPayment = false;
          this.alert = false;
        }, 5000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.the-title {
  font-size: 1.8rem !important;
}
</style>
