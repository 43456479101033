import axios from "axios";
import getAPIUrl from "../utils/helpers/getAPIUrl";
// import https from "https"; // Importa el módulo 'https'

// const tlsAgent = new https.Agent({
//   rejectUnauthorized: true, // Habilita la verificación del certificado
//   secureProtocol: "TLSv1_2_method", // Protocolo TLS específico (TLS 1.2 en este caso)
// });

axios.interceptors.request.use(
  async (config) => {
    config.baseURL = await getAPIUrl();
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.Accept = "application/json";

    // Asigna el agente TLS personalizado a la solicitud
    // config.httpsAgent = tlsAgent;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axios;
