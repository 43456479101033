import axios from "@/axios/axios-api";

const actions = {
  async getIntencionPagoPlusPagos(context, payload) {
    try {
      const response = await axios.get(
        `api/AppBenef/pago-plus-pagos?compId=${payload}`
      );

      return response;
    } catch (error) {
      return {
        status: 400,
        data: error.response.data,
      };
    }
  },
  async executePagoPlusPagos(context, payload) {
    const response = await axios.post(
      `api/AppBenef/plus-pagos-payment`,
      payload
    );
    return response.data;
  },
 
};

export default actions;
