<template>
  <div class="landing-page">
    <h1 class="title">Gecros Pagos</h1>
    <v-btn to="/plus-pagos?comp=U2FsdGVkX1%2FVW6DYg6BYteGeUFUx7aq9dEe2fZlc9Ac%3D&returnUrl=https%3A%2F%2Fcryptojs.gitbook.io%2Fdocs%2F" outlined class="primary-text"
      >Ir a Gecros pagos</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "LandingPage",
  methods: {
    limpiarToken() {
      localStorage.setItem("token", null);
    },
  },
};
</script>

<style>
.landing-page {
  text-align: center;
  padding: 100px 0;
}

.title {
  font-size: 2rem;
  margin-bottom: 20px;
}

.btn {
  padding: 10px 20px;
  background-color: #1120c7;

  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #0056b3;
}
</style>
