import Vue from "vue";
import VueRouter from "vue-router";
import LandingPage from "@/components/LandingPage.vue";
import PlusPagosForm from "@/components/PlusPagosForm.vue";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
  },
  {
    path: "/plus-pagos",
    name: "PlusPagosForm",
    component: PlusPagosForm,
    props: (route) => ({
      comp: route.query.comp,
      returnUrl: route.query.returnUrl || null,
    }),
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
export default router;
